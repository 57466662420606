import { Injectable } from '@angular/core';
import { Classification, Product, ReferenceEntry } from '../pim-graphql.model';
import { LocaleService } from 'src/app/services/locale.service';
import { ValidityDateService } from 'src/app/services/validity-date.service';

@Injectable({
  providedIn: 'root',
})
export class SharedViewService {
  constructor(
    private validityService: ValidityDateService,
    private locales: LocaleService,
  ) {}

  //Sort by AT_PosId as GraphQL metadata sort doesnt work [VIESS-133]
  sortByMetadataAttribute(attribute: string, entries?: ReferenceEntry[]) {
    return entries?.sort((a, b) => {
      const valA = a.values.find((val) => val.attribute.id === attribute);
      const posA = valA?.simpleValue ?? valA?.values?.[0]?.value;
      const valB = b.values.find((val) => val.attribute.id === attribute);
      const posB = valB?.simpleValue ?? valB?.values?.[0]?.value;

      if (posB == undefined || isNaN(+posB)) {
        return -1;
      } else if (posA == undefined || isNaN(+posA)) {
        return 1;
      }

      return +posA - +posB;
    });
  }

  isValidForMarket(product: Product): boolean {
    const currentValidityDate = this.validityService
      ?.getValidityDate()
      ?.getTime();

    if (!currentValidityDate) {
      return true;
    }

    const dataContainers = product.dataContainers?.find(
      (con) => con.dataContainerType.id === 'DC_SalesOrg',
    )?.dataContainers;

    const dataCon = dataContainers?.find((con) => {
      //"includes()" to match an empty salesOrg on every val
      return con.values?.some(
        (val) =>
          val.attribute.id === 'AT_SalesOrg_Desc' &&
          val.simpleValue?.includes(this.locales.getCurrentLocale().salesOrg),
      );
    });

    const from = dataCon?.values?.find(
      (val) => val.attribute.id === 'AT_ValidFrom',
    )?.simpleValue;

    const to = dataCon?.values?.find(
      (val) => val.attribute.id === 'AT_ValidUntil',
    )?.simpleValue;

    if (!from || !to) {
      return false;
    }
    const fromDate = new Date(from).getTime();
    const toDate = new Date(to).getTime();

    return currentValidityDate
      ? fromDate <= currentValidityDate && currentValidityDate <= toDate
      : true;
  }

  isValidMarket(classification: Classification): boolean {
    const currentValidityDate = this.validityService
      ?.getValidityDate()
      ?.getTime();

    if (!currentValidityDate) {
      return true;
    }
    const validityContainer = classification.valuesByDataTypeGroup?.find(
      (vcg) => vcg.dataTypeGroup.id === 'ATG_AppStructureValidities',
    );

    return (
      validityContainer?.values.find(
        (value) =>
          value.attribute.id.startsWith('AT_Valid_') &&
          value.attribute.id.endsWith(this.locales.getCurrentLocale().salesOrg),
      )?.simpleValue === 'true'
    );
  }
}
